<template>
  <div class="content-box-padding">
    <div class="line-text" style="margin-top: 20px">
      基于多源融合、人工智能深度学习技术和气象数值模式技术，实现全气象要素0-168小时全时序无缝隙精细化监测分析与预报预警，并通过大数据挖掘结合细分行业领域内在需求，通过气象与行业大数据融合为各类用户提供基于行业影响的专业气象咨询服务产品，为行业用户提供气象服务保障支撑。
    </div>
    <img
      src="~assets\image\business\智慧气象整体解决方案.png"
      alt="智慧气象整体解决方案"
      style="margin: 20px auto 80px auto"
    />

    <div class="sub-title">对流初生智能预警平台</div>
    <div class="line-text" style="margin-top: 20px">
      基于AI技术，根据积云单体的发展变化趋势建模，在雷达尚未观测到对流云团时，利用卫星识别潜在发展云团，使行业用户能提前预知对流发生，从而达到预报短时对流活动的目的，提升短时强对流天气的预报预警能力及防灾减灾水平。
    </div>
    <img
      src="~assets\image\business\对流初生智能预警平台.png"
      alt="对流初生智能预警平台"
      style="margin: 20px auto 80px auto"
    />

    <div class="sub-title">降水反演智能分析平台</div>
    <div class="line-text" style="margin-top: 20px">
      采用机器学习建模，生成基于卫星的降水反演产品，在海上等雷达资料缺失的区域作为补盲监测手段，同时也可与雷达资料融合，为沿海地区、海域等区域提供气象服务，提升短时强对流天气的监测预警能力。
    </div>
    <img
      src="~assets\image\business\降水反演智能分析平台.png"
      alt="降水反演智能分析平台"
      style="margin: 40px auto 80px auto"
    />

    <div class="sub-title">短临智能外推预报平台</div>
    <div class="line-text" style="margin-top: 20px">
      基于机器学习技术，修订雷达外推预报模型的强度预报，在传统方法的基础上，将雷达外推与数值模式预报相结合，有效提高预报准确率，实现未来2小时分钟级回波的移动、生消预报预测，帮助行业用户更好地把握对流发展变化趋势，提前做出决策判断。
    </div>
    <img
      src="~assets\image\business\短临智能外推预报平台.png"
      alt="短临智能外推预报平台"
      style="margin: 40px auto 80px auto"
    />

    <div class="sub-title">智能网格融合预报平台</div>
    <div class="line-text" style="margin-top: 20px">
      基于数值模式数据，结合频率匹配订正和多层卷积神经网络技术，对温度、风、降水使用实况与模式资料进行训练，输出基于AI的智能网格模式实时偏差订正产品，对数值模式产品进行订正，提高智能网格预报的准确率。
    </div>
    <img
      src="~assets\image\business\智能网格融合预报平台.png"
      alt="智能网格融合预报平台"
      style="margin: 40px auto 80px auto"
    />

    <div class="sub-title">数值模式智能检验平台</div>
    <div class="line-text" style="margin-top: 20px; width: 102%">
      针对RUC等模式的降水、风、温等各类要素进行客观检验评分，提高精细化数值天气预报，尤其是提升对灾害性天气精细化预警预报的支持能力，为预报业务人员、模式开发人员进一步分析模式表现、改进模式提供参考依据与支撑。
    </div>
    <img
      src="~assets\image\business\数值模式智能检验平台.png"
      alt="数值模式智能检验平台"
      style="margin: 40px auto 0 auto"
    />
  </div>
</template>
